import request from '@/utils/request'
// 预警-干预档案
// 获取干预列表
export function getInterveneList(data) {
    return request({
      url: '/warning/intervene',
      method: 'post',
      data
    });
}
// 获取干预档案
export function getArchives(params) {
  return request({
    url: '/warning/intervene/file',
    method: 'get',
    params
  });
}
// 修改、新增干预档案
export function updateArchives(data) {
  return request({
    url: '/warning/save/intervene',
    method: 'post',
    data
  });
}
// 删除干预档案
export function deleteArchives(data) {
  return request({
    url: '/warning/intervene',
    method: 'delete',
    data
  });
}
// 获取咨询师下拉框
export function getTeachersList(params) {
  return request({
    url: '/warning/intervene/Consultant',
    method: 'get',
    params
  });
}