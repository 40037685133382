<template>
  <!-- 查看报告 -->
  <div>
    <Archives></Archives>
  </div>
</template>

<script>
import Archives from '@/components/report/Archives.vue'
export default {
  
  components: {
    Archives
  }
}
</script>

<style lang="scss" scoped>

</style>